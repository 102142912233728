import React from "react"
import { Router } from "@reach/router"
import HomePage from "../components/HomePage"
import Manager from "./manager"
// import Enter from "./enter"

const IndexPage = () => {
  return (
    <>
      <Router>
        <HomePage path="/" />
        <Manager path="/manager" />
        {/* <Enter path="/enter" /> */}
      </Router>
    </>
  )
}

export default IndexPage
