import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import Container from "@material-ui/core/Container"
import HomeLogo from "./HomeLogo"
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  headerLink: {
    fontSize: "10rem",
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}))

const HomePage = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography component="h1" gutterBottom>
          <Link className={classes.headerLink} to="/manager">
            Vize
          </Link>
        </Typography>
        <HomeLogo />
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Typography variant="body1">Vize for Segmentnation</Typography>
        </Container>
      </footer>
    </div>
  )
}

export default HomePage
